import { ChartDonut, ChartThemeColor } from "@patternfly/react-charts";
import "./LeafMiniSegmentedCircle.scss";
import { useEffect, useRef } from "react";
import LeafCircleLoader from "../LeafLoadingSkeleton/LeafCircleLoader/LeafCircleLoader";

export type FormattedLabelValues = {
  currentAmount?: string;
  targetAmount?: string;
};

export type Props = {
  label: string;
  ariaTitle: string;
  horizontal?: boolean;
  currentAmount?: number;
  targetAmount?: number;
  totalSegments?: number;
  filledSegments?: number;
  isLoading?: boolean;
  animationDuration?: number;
  met?: boolean;
};

const LeafMiniSegmentedCircle = (props: Props) => {
  const {
    label,
    horizontal = false,
    currentAmount = 0,
    targetAmount = 0,
    totalSegments = 0,
    filledSegments = 0,
    isLoading = false,
    animationDuration = 0,
    ariaTitle,
    met = false,
  } = props;
  const ref = useRef(null);

  const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
  const percentage = currentAmount / targetAmount;
  const currentPercentage = met ? 1 : clamp(percentage, 0, 1);
  const greyColor = "#E4E4E4";
  const orangeColor = "#DA7600";
  const greenColor = "#107F47";

  const data = [];
  let className = "";
  if (totalSegments === 0) {
    data.push({ x: 0, y: targetAmount === currentAmount || met ? 100 : currentAmount });
    data.push({ x: 100, y: met ? Math.min(0, targetAmount - currentAmount) : targetAmount - currentAmount });
    className = "Circle__no-segments";
  } else {
    for (let i = 0; i < totalSegments; i++) {
      data.push({ x: "", y: 100 / totalSegments - 1 });
      data.push({ x: "", y: 1 });
    }
    className = "Circle__segments";
  }

  // this controls the colors on the progress wheel
  useEffect(() => {
    if (ref && ref.current) {
      const progressBar = (ref.current as HTMLElement).querySelector(
        ".pf-v5-c-chart > svg > g > path:nth-child(1)",
      ) as HTMLElement;

      if (totalSegments === 0) {
        if (currentPercentage <= 0) {
          progressBar.style.fill = greyColor;
        } else if (currentPercentage < 1) {
          progressBar.style.fill = orangeColor;
        } else if (currentPercentage === 1) {
          progressBar.style.fill = greenColor;
        }
      } else {
        const segmentNodes = [];
        for (let i = 1; i < totalSegments * 2; i = i + 2) {
          segmentNodes.push(
            (ref.current as HTMLElement).querySelector(
              ".pf-v5-c-chart > svg > g > path:nth-child(" + i + ")",
            ) as HTMLElement,
          );
        }
        if (filledSegments >= totalSegments) {
          for (let i = 0; i < segmentNodes.length; i++) {
            segmentNodes[i].style.fill = greenColor;
          }
        } else {
          for (let i = 0; i < segmentNodes.length; i++) {
            if (i < filledSegments) {
              segmentNodes[i].style.fill = orangeColor;
            } else {
              segmentNodes[i].style.fill = greyColor;
            }
          }
        }
      }
    }
  }, [currentAmount, targetAmount, currentPercentage, totalSegments, filledSegments]);

  if (isLoading) {
    return (
      <div className="LeafMiniSegmentedCircle">
        <div className="LeafProgressCircle__Loader">
          <LeafCircleLoader isLoading={isLoading} width="100%" height="100%" />
        </div>
      </div>
    );
  }

  const animationSettings = {
    duration: animationDuration,
    onEnter: {
      duration: animationDuration,
    },
    onExit: {
      duration: animationDuration,
    },
  };

  return (
    <div
      className={
        "leaf-miniseg-circle-container " + (horizontal ? "horizontalSegmentsCircle" : "verticalSegmentsCircle")
      }
      ref={ref}
    >
      <div className={"LeafMiniSegmentedCircle " + className} data-testid={"leafMiniSegmentedCircle"}>
        <ChartDonut
          themeColor={ChartThemeColor.gray}
          constrainToVisibleArea
          data={data}
          innerRadius={65}
          cornerRadius={3}
          padAngle={0}
          animate={animationDuration > 0 ? animationSettings : false}
          ariaTitle={ariaTitle}
        />
      </div>
      <div className="LeafMiniSegmentedCircle__label" data-testid={"leafMiniSegmentedCircle-label"}>
        {label}
      </div>
    </div>
  );
};

export default LeafMiniSegmentedCircle;
